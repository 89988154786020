<!--
 * @Descripttion: 
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-12-13 16:24:30
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2020-12-13 17:04:11
-->
<template>
  <div>
    <popup v-model="syncShow" class="time-pop-box">
      <div class="time-pop">
        <h2 class="time-pop__name">勘察时间</h2>
        <div class="time-pop__row" @click="showDatePicker = true">
          <span class="time-pop__title time-pop__title--required">日期</span>
          <span class="time-pop__picker">
            <input type="text" placeholder="选择日期" disabled :value="date" />
          </span>
        </div>
        <div class="time-pop__row">
          <span class="time-pop__title time-pop__title--required">时间</span>
          <div
            class="time-pop__time"
            :data-start="startTime ? startTime.text : '选择时间'"
            :data-end="endTime ? endTime.text : '选择时间'"
            @click="openTimePicker('start')"
          ></div>
        </div>
        <div class="time-pop__row">
          <span class="time-pop__title">备注</span>
        </div>
        <div class="time-pop__area">
          <textarea
            placeholder="请输入备注信息"
            maxlength="100"
            v-model="remarks"
          ></textarea>
          <div class="time-pop__area__notice">限100字内</div>
        </div>
        <div class="time-pop-btns">
          <div class="time-pop__btn" @click="submit">提交</div>
          <div class="time-pop__btn" @click="cancel">取消</div>
        </div>
      </div>
    </popup>

    <popup position="bottom" v-model="showDatePicker">
      <datetime-picker
        type="date"
        title="选择日期"
        :min-date="new Date()"
        @confirm="selectDate"
        @cancel="showDatePicker = false"
      />
    </popup>

    <popup position="bottom" v-model="showTimePicker">
      <!-- <datetime-picker
        type="datehour"
        :title="timeType === 'start' ? '选择开始时间' : '选择结束时间'"
        @confirm="selectTime"
      /> -->
      <picker
        :columns="hours"
        :title="timeType === 'start' ? '选择开始时间' : '选择结束时间'"
        @confirm="selectTime"
        @cancel="showTimePicker = false"
        value-key="text"
        show-toolbar
      />
    </popup>
  </div>
</template>

<script>
import { Popup, DatetimePicker, Picker } from "vant";
import formatDate from "../../utils/formatDate";
export default {
  components: {
    popup: Popup,
    "datetime-picker": DatetimePicker,
    picker: Picker,
  },
  props: {
    value: Boolean,
  },
  data() {
    return {
      syncShow: this.value,
      showDatePicker: false, // 显示日期选择器

      date: "", // 选择日期

      showTimePicker: false, // 日期选择器

      hours: [], // 时间选项

      timeType: "start", // 时间类型 start开始时间 end结束时间

      startTime: "",
      endTime: "",

      remarks: "", // 备注
    };
  },
  created() {
    let hours = [];
    for (let i = 0; i < 24; i++) {
      hours.push({
        value: i,
        text: `${i < 10 ? "0" + i : i}:00`,
      });
    }
    this.hours = hours;
  },
  methods: {
    submit() {
      this.$emit("onsubmit", {
        detail: {
          date: this.date,
          hour: {
            start: this.startTime.value,
            end: this.endTime.value,
          },
          remarks: this.remarks,
        },
      });
    },
    cancel() {
      this.$emit("input", false);
      this.remarks = "";
      this.$emit("oncancel");
    },

    selectDate(e) {
      this.date = formatDate(e, "yyyy-MM-dd");
      this.showDatePicker = false;
    },

    // 打开时间选择器
    openTimePicker(type) {
      this.timeType = type;
      this.showTimePicker = true;
    },

    // 选择日期
    selectTime(e) {
      if (this.timeType === "start") {
        this.startTime = e;
        this.timeType = "end";
      } else {
        this.timeType = "start";
        this.endTime = e;
        this.showTimePicker = false;
      }
    },
  },
  watch: {
    syncShow(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.syncShow = newVal;
    },
  },
};
</script>

<style scoped>
.time-pop-box {
  border-radius: 16px;
}

.time-pop {
  width: 552px;
  padding: 47px 48px 40px;
  box-sizing: border-box;
}

.time-pop__name {
  text-align: center;
  font-size: 36px;
}

.time-pop__title {
  font-size: 32px;
}

.time-pop__title--required::before {
  content: "*";
  color: var(--deputy-color);
}

.time-pop__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.time-pop__row {
  margin-top: 35px;
}

.time-pop__picker {
  font-size: 24px;
  display: flex;
  align-items: center;
}

.time-pop__picker > input {
  text-align: right;
}

.time-pop__picker > input::placeholder {
  color: #ccc;
}

.time-pop__picker::after {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  background-image: url("../../assets/images/service/time_pop_arrow.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 12px;
}

.time-pop__time {
  width: 54px;
  height: 3px;
  background-color: #999;
  position: relative;
  margin-right: 142px;
}

.time-pop__time::before {
  content: attr(data-start);
  left: -144px;
}

.time-pop__time::after {
  content: attr(data-end);
  right: -144px;
}

.time-pop__time::before,
.time-pop__time::after {
  width: 122px;
  height: 40px;
  color: #ccc;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-bottom: 1px solid #999;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
}

.time-pop__area {
  border-radius: 8px;
  border: 1px solid #d2d2d2;
  margin-top: 17px;
  padding: 24px 16px;
}

.time-pop__area > textarea {
  width: 100%;
  border: none;
  resize: none;
  height: 182px;
  box-sizing: border-box;
  font-size: 24px;
}

.time-pop__area > textarea::placeholder {
  color: #ccc;
}

.time-pop__area__notice {
  text-align: right;
  width: 100%;
  font-size: 24px;
  color: #ccc;
}

.time-pop-btns {
  margin-top: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.time-pop__btn {
  width: 192px;
  height: 66px;
  border-radius: 33px;
  background-color: var(--main-color);
  color: white;
  line-height: 66px;
  text-align: center;
}

.time-pop__btn:last-child {
  background-color: #ccc;
}

input:disabled {
  background-color: transparent;
  pointer-events: none;
}
</style>